import {
  assumeRole,
  Category,
  createCategory,
  createVariantList,
  deleteCategory,
  deleteVariantList,
  editCategory,
  editSample,
  fetchSampleById,
  getCategory,
  GetManyVariantIndexDto,
  getSignedUrl,
  getVariantIndexData,
  getVariantList,
  IVariantIndexData,
  IVariantIndexResult,
  OssSignedUrlType,
  Sample,
  VariantList
} from '@/@core/services/app.service'
import { formatDate } from '@/@core/utils/utils'
import Index from '@/views/analyze/Index'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TableContainer,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  Divider,
  Card,
  CardContent,
  LinearProgressProps,
  LinearProgress,
  CardHeader
} from '@mui/material'
import { Box } from '@mui/system'
import axios, { AxiosRequestConfig } from 'axios'
import { Close, Plus } from 'mdi-material-ui'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import OSS from 'ali-oss'
import { parseUrlQuery } from '@/@core/layouts/utils'

interface NObject {
  [key: string]: any
}

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

const UpBamFile = (props: any) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { id } = router.query
  const [bamProcess, setBamProcess] = React.useState<number>(0)
  const [bamIndexProcess, setBamIndexProcess] = React.useState<number>(0)
  const [BamFile, setBamFile] = React.useState<File[]>([])
  const [BamIndexFile, setBamIndexFile] = React.useState<File[]>([])
  const [abortCheckpointBam, setAbortCheckpointBam] = React.useState<any>()
  const [abortCheckpointBamIndex, setAbortCheckpointBamIndex] =
    React.useState<any>()
  const [client, setClient] = React.useState<any>()
  const [retryCount, setRetryCount] = React.useState(0)
  const retryCountMax = 3

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'Bam' | 'BamIndex'
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      console.log(event.target.files[0].name, BamFile, BamIndexFile)

      if (type === 'BamIndex' && BamFile.length) {
        const bamFileName = BamFile[0].name
        const bamIndexFileName = event.target.files[0].name

        const flag = bamIndexFileName.includes(`${bamFileName}.bai`)

        if (flag) {
          handleUpload(event.target.files[0], type)

          setBamIndexFile([event.target.files[0]])
        } else {
          console.error(
            'bam索引文件必须与bam文件具有相同的名称，并附加后缀:".bai"。如。bam文件= abc.bam，那么bam索引文件就是:abc.Bam.bai'
          )
        }
      } else if (type === 'Bam' && BamIndexFile.length) {
        const bamFileName = event.target.files[0].name
        const bamIndexFileName = BamIndexFile[0].name

        const flag = bamIndexFileName.includes(`${bamFileName}.bai`)

        if (flag) {
          handleUpload(event.target.files[0], type)

          setBamFile([event.target.files[0]])
        } else {
          console.error(
            'bam索引文件必须与bam文件具有相同的名称，并附加后缀:".bai"。如。bam文件= abc.bam，那么bam索引文件就是:abc.Bam.bai'
          )
        }
      } else if (type === 'BamIndex' && !BamIndexFile.length) {
        handleUpload(event.target.files[0], type)

        setBamIndexFile([event.target.files[0]])
      } else if (type === 'Bam' && !BamFile.length) {
        handleUpload(event.target.files[0], type)

        setBamFile([event.target.files[0]])
      }
    }
  }

  const handleOss = async () => {
    // 向您搭建的STS服务获取临时访问凭证。
    const query = await handleGetAssumeRole.mutateAsync()

    // if (!query || !query.SecurityToken) return
    const client = new OSS({
      // yourRegion填写Bucket所在地域。
      region: 'oss-cn-shenzhen',

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: query.AccessKeyId,
      accessKeySecret: query.AccessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: query.SecurityToken,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 30000000,

      // 填写Bucket名称。
      bucket: 'glsofort',
      refreshSTSToken: async () => {
        const query = await handleGetAssumeRole.mutateAsync()

        console.log('query', query)

        return {
          accessKeyId: query.AccessKeyId,
          accessKeySecret: query.AccessKeySecret,
          stsToken: query.SecurityToken
        }
      }
    })

    setClient(client)

    return client
  }

  const upFile = async (
    client: OSS,
    file: File,
    type: 'Bam' | 'BamIndex',
    option?: OSS.MultipartUploadOptions
  ) => {
    const headers = {
      // 指定该Object被下载时的网页缓存行为。
      'Cache-Control': 'no-cache',

      // 指定该Object被下载时的名称。
      'Content-Disposition': file.name,

      // 指定该Object被下载时的内容编码格式。
      'Content-Encoding': 'utf-8',

      // 指定过期时间，单位为毫秒。
      Expires: '1000',

      // // 指定Object的存储类型。
      // 'x-oss-storage-class': 'Standard',

      // // 指定Object标签，可同时设置多个标签。
      // 'x-oss-tagging': 'Tag1=1&Tag2=2',

      // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
      'x-oss-forbid-overwrite': 'false'
    }
    let options: OSS.MultipartUploadOptions = {
      // 获取分片上传进度、断点和返回值。
      progress: (p: any, cpt: any, res: any) => {
        console.log('progress', cpt)

        // 获取进度以及上传文件分片
        if (type === 'Bam') {
          setBamProcess(p * 100)
          if (cpt && Object.keys(cpt).length) setAbortCheckpointBam(cpt)
        } else {
          setBamIndexProcess(p * 100)
          if (cpt) setAbortCheckpointBamIndex(cpt)
        }
      },

      // 设置并发上传的分片数量。
      parallel: 2,

      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024 * 10,
      headers,
      mime: 'text/plain',
      timeout: 120000 //设置超时时间,
    }
    if (option) options = option

    // const client = await handleOss()

    console.log(client)

    if (client) {
      // 上传文件
      const data = await client
        .multipartUpload(`bam_uploads/${file.name}`, file, {
          ...options
        })
        .catch(async (err) => {
          const strErr = JSON.stringify(err)

          console.log(strErr)

          console.log('123', client)
          if (strErr.includes('abort')) return

          //更新token
          // const upClient = await handleOss()

          // 判断是否存在分片数据以及重启次数
          if (
            client &&
            (abortCheckpointBam || abortCheckpointBamIndex) &&
            retryCount < retryCountMax
          ) {
            setRetryCount(retryCount + 1)

            console.error('retryCount : ' + retryCount)
            const option = {
              ...options
            }

            // 设置分片
            if (type === 'Bam' && abortCheckpointBam) {
              option.checkpoint = abortCheckpointBam
            } else if (type === 'BamIndex' && abortCheckpointBamIndex) {
              option.checkpoint = abortCheckpointBamIndex
            }

            // 轮询
            await upFile(client, file, type, option)

            console.error(abortCheckpointBam)
          }
        })

      console.log('data', data)

      if (data && type === 'Bam') {
        setAbortCheckpointBam(null)

        // setClient(null)

        console.log(props.analysisInfo)

        if (props.analysisInfo && Object.keys(props.analysisInfo).length) {
          handleEditSam.mutateAsync({
            id: props.analysisInfo.id,
            name: props.analysisInfo.name,
            genomeBuild: props.analysisInfo.genomeBuild,
            gender: props.analysisInfo.gender,
            status: props.analysisInfo.status,
            bamFile: file.name
          } as Sample)
        } else
          getSample.mutateAsync({
            id: id as unknown as number,
            name: file.name
          })
      } else if (data && type === 'BamIndex') {
        setAbortCheckpointBamIndex(null)

        // setClient(null)
      }
    }
  }

  const handleUpload = async (
    file: File,
    type: 'Bam' | 'BamIndex',
    option?: OSS.MultipartUploadOptions
  ) => {
    if (file) {
      try {
        console.log(file.name, option)

        const headers = {
          // 指定该Object被下载时的网页缓存行为。
          'Cache-Control': 'no-cache',

          // 指定该Object被下载时的名称。
          'Content-Disposition': file.name,

          // 指定该Object被下载时的内容编码格式。
          'Content-Encoding': 'utf-8',

          // 指定过期时间，单位为毫秒。
          Expires: '1000',

          // // 指定Object的存储类型。
          // 'x-oss-storage-class': 'Standard',

          // // 指定Object标签，可同时设置多个标签。
          // 'x-oss-tagging': 'Tag1=1&Tag2=2',

          // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
          'x-oss-forbid-overwrite': 'false'
        }
        let options: OSS.MultipartUploadOptions = {
          // 获取分片上传进度、断点和返回值。
          progress: (p: any, cpt: any, res: any) => {
            // console.log('progress', cpt)

            if (type === 'Bam') {
              setBamProcess(p * 100)
              if (cpt && Object.keys(cpt).length) setAbortCheckpointBam(cpt)
            } else {
              setBamIndexProcess(p * 100)
              if (cpt) setAbortCheckpointBamIndex(cpt)
            }
          },

          // 设置并发上传的分片数量。
          parallel: 2,

          // 设置分片大小。默认值为1 MB，最小值为100 KB。
          partSize: 1024 * 1024 * 10,
          headers,
          mime: 'text/plain',
          timeout: 120000 //设置超时时间,
        }
        if (option) options = option

        // 设置分片
        if (type === 'Bam' && abortCheckpointBam) {
          options.checkpoint = abortCheckpointBam
        } else if (type === 'BamIndex' && abortCheckpointBamIndex) {
          options.checkpoint = abortCheckpointBamIndex
        }

        // 获取token
        const client = await handleOss()

        // 开始上传文件
        await upFile(client, file, type, options)

        // console.log(client)

        // if (client) {
        //   const data = await client
        //     .multipartUpload(`bam_uploads/${file.name}`, file, {
        //       ...options
        //     })
        //     .catch(async (err) => {
        //       console.error(
        //         'file upload error',
        //         err,
        //         client,
        //         abortCheckpointBam,
        //         abortCheckpointBamIndex
        //       )

        //       if (
        //         client &&
        //         (abortCheckpointBam || abortCheckpointBamIndex) &&
        //         retryCount < retryCountMax
        //       ) {
        //         setRetryCount(retryCount + 1)

        //         console.error('retryCount : ' + retryCount)
        //         const option = {
        //           ...options
        //         }

        //         if (type === 'Bam' && abortCheckpointBam) {
        //           option.checkpoint = abortCheckpointBam
        //         } else if (type === 'BamIndex' && abortCheckpointBamIndex) {
        //           option.checkpoint = abortCheckpointBamIndex
        //         }

        //         await handleUpload(file, type, option)

        //         console.error(abortCheckpointBam)
        //       }
        //     })

        //   console.log('data', data)

        //   if (data && type === 'Bam') {
        //     setAbortCheckpointBam(null)

        //     setClient(null)

        //     getSample.mutateAsync({
        //       id: id as unknown as number,
        //       name: file.name
        //     })
        //   } else if (data && type === 'BamIndex') {
        //     setAbortCheckpointBamIndex(null)

        //     setClient(null)
        //   }
        // }
      } catch (error) {
        console.error('Upload error:', error)
      }
    }
  }

  // 修改样本
  const handleEditSam = useMutation({
    mutationFn: async (data: Sample) => await editSample(data),
    onSuccess: (data: string) => {
      if (data) {
        props.refetchAnalyzeInfo && props.refetchAnalyzeInfo()
      }
    }
  })

  const handleGetSignedUrl = useMutation({
    mutationFn: async (data: { type: OssSignedUrlType; obj: string }) =>
      await getSignedUrl(data),
    onSuccess: () => {
      //
    }
  })

  const handleGetAssumeRole = useMutation({
    mutationFn: async () => await assumeRole(),
    onSuccess: () => {
      //
    }
  })

  const getSample = useMutation({
    mutationFn: async (data: { id: number; name: string }) =>
      await fetchSampleById(id),
    onSuccess: (sampe: Sample, params) => {
      handleEditSam.mutateAsync({
        id: sampe.id,
        name: sampe.name,
        projectId: sampe.projectId,
        genomeBuild: sampe.genomeBuild,
        gender: sampe.gender,
        status: sampe.status,
        bamFile: params.name
      } as Sample)
    }
  })

  return (
    <Box>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {t('Upload BAM File')}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>

      <DialogContent dividers>
        <Grid container width={'100%'} height={'100%'} spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{
                  '& .MuiCardHeader-title': {
                    fontSize: '1rem'
                  }
                }}
                title={'BAM File *'}
              ></CardHeader>

              <CardContent>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Button size="small" variant="contained" component="label">
                    <input
                      onChange={(e) => {
                        handleFileChange(e, 'Bam')
                      }}
                      type="file"
                      hidden
                      accept=".bam"
                    />

                    <Typography color={'white'}>{t('Browse')}</Typography>
                  </Button>

                  <Box width={'100%'}>
                    {BamFile.length ? (
                      <Stack
                        width={'100%'}
                        direction={'row'}
                        alignItems="center"
                        justifyContent={'space-between'}
                      >
                        <Typography>
                          {BamFile[0].name}

                          <IconButton
                            onClick={async () => {
                              setBamFile([])

                              setBamProcess(0)

                              if (
                                abortCheckpointBam &&
                                abortCheckpointBam.name &&
                                abortCheckpointBam.uploadId
                              ) {
                                // const client = await handleOss()

                                await client?.abortMultipartUpload(
                                  abortCheckpointBam.name,
                                  abortCheckpointBam.uploadId
                                )

                                setAbortCheckpointBam(null)

                                // setClient(null)
                              }
                            }}
                          >
                            <Close sx={{ fontSize: '1.125rem' }} />
                          </IconButton>
                        </Typography>

                        <Typography>
                          {bamProcess !== 100 ? t('uploading') : t('completed')}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography>{t('Unselected File')}</Typography>
                    )}
                  </Box>
                </Stack>

                <LinearProgressWithLabel
                  value={bamProcess}
                  color={bamProcess !== 100 ? 'secondary' : 'success'}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{
                  '& .MuiCardHeader-title': {
                    fontSize: '1rem'
                  }
                }}
                title={'Index File *'}
              ></CardHeader>

              <CardContent>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                  <Button size="small" variant="contained" component="label">
                    <input
                      onChange={(e) => {
                        handleFileChange(e, 'BamIndex')
                      }}
                      type="file"
                      hidden
                      accept=".bai"
                    />

                    <Typography color={'white'}>{t('Browse')}</Typography>
                  </Button>

                  <Box width={'100%'}>
                    {BamIndexFile.length ? (
                      <Stack
                        width={'100%'}
                        direction={'row'}
                        alignItems="center"
                        justifyContent={'space-between'}
                      >
                        <Typography>
                          {BamIndexFile[0].name}

                          <IconButton
                            onClick={async () => {
                              setBamIndexProcess(0)

                              setBamIndexFile([])

                              if (
                                abortCheckpointBamIndex &&
                                abortCheckpointBamIndex.name &&
                                abortCheckpointBamIndex.uploadId
                              ) {
                                // const client = await handleOss()

                                await client?.abortMultipartUpload(
                                  abortCheckpointBamIndex.name,
                                  abortCheckpointBamIndex.uploadId
                                )

                                setAbortCheckpointBamIndex(null)

                                // setClient(null)
                              }
                            }}
                          >
                            <Close sx={{ fontSize: '1.125rem' }} />
                          </IconButton>
                        </Typography>

                        <Typography>
                          {bamIndexProcess !== 100
                            ? t('uploading')
                            : t('completed')}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography>{t('Unselected File')}</Typography>
                    )}
                  </Box>
                </Stack>

                <LinearProgressWithLabel
                  value={bamIndexProcess}
                  color={bamIndexProcess !== 100 ? 'secondary' : 'success'}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            mt: 3,
            mb: 3
          }}
          variant={'contained'}
          onClick={props.handleClose}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Box>
  )
}

export default UpBamFile
