// ** MUI Imports
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

// ** Type Imports
import { CustomRadioIconsProps } from 'src/@core/components/custom-radio/types'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

const CustomRadioIcons = (props: CustomRadioIconsProps) => {
  // ** Props
  const {
    data,
    icon,
    name,
    selected,
    gridProps,
    iconProps,
    handleChange,
    color = 'primary',
    disabled
  } = props

  const { title, value, content } = data

  const renderComponent = () => {
    return (
      <Grid item {...gridProps}>
        <Box
          onClick={() => {
            if (!disabled) handleChange(value)
          }}
          sx={{
            pt: 4,
            pb: 4,
            pr: 2,
            pl: 2,
            height: '100%',
            display: 'flex',
            borderRadius: 1,
            cursor: 'pointer',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            ...(selected === value
              ? { borderColor: `${color}.main` }
              : {
                  '&:hover': {
                    borderColor: (theme) =>
                      `rgba(${theme.palette.customColors.main}, 0.25)`
                  }
                })
          }}
        >
          <Stack>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              {icon ? (
                <Icon
                  icon={icon}
                  {...iconProps}
                  fontSize={'1.5rem'}
                  color={selected === value ? '#08AFC4' : 'black'}
                />
              ) : null}

              {title ? (
                typeof title === 'string' ? (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: selected === value ? '#08AFC4' : 'black'
                    }}
                  >
                    {title}
                  </Typography>
                ) : (
                  title
                )
              ) : null}
            </Stack>

            {content ? (
              typeof content === 'string' ? (
                <Typography
                  variant="body2"
                  sx={{ my: 'auto', textAlign: 'center', fontSize: '14px' }}
                >
                  {content}
                </Typography>
              ) : (
                content
              )
            ) : null}
          </Stack>

          <Radio
            name={name}
            size="small"
            color={color}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            checked={selected === value}
          />
        </Box>
      </Grid>
    )
  }

  return data ? renderComponent() : null
}

export default CustomRadioIcons
